<template>
	<div class="card-outer" @click="viewTour()" :style="{'background-image': 'url(' + image + ')'}">
		<div class="content">
			<div class="name">{{ name }}</div>
			<div class="length">
				<img src="@/assets/tours/day-icon-white.png" alt="Sunset icon"/>
				<span v-if="length == 1">
					Day trip
				</span>
				<span v-else>
					{{ length }} days
				</span>
			</div>
			<div class="location">
				<img class="location-icon" src="@/assets/tours/location-icon-blue.png">
				<span class="start">{{ startLocation }}</span>
				<span class="end" v-if="endLocation && endLocation != startLocation"> - {{ endLocation }}</span>
			</div>
			<div class="price">
				<span class="amount">
					{{ currencySymbol(fromPrice.currency) }}{{ fromPrice.amount.substr(0, fromPrice.amount.indexOf('.')) }}<span class="small">{{ fromPrice.amount.substr(fromPrice.amount.indexOf('.'), fromPrice.amount.length) }}</span>
				</span>
			</div>
		</div>
		
		<!-- <div class="clear"></div> -->
	</div>
</template>

<script>
	import { mapState, mapActions } from "vuex";
	import router from '@/router';
	import helpers from '@/helpers/helpers.js';

    export default {
        name: 'TourTallCard',
        props: {
			id: {
				default: null,
			},
			name: {
				default: null,
			},
			images: {
				default: null,
			},
			startLocation: {
				default: null,
			},
			endLocation: {
				default: null,
			},
			length: {
				default: null,
			},
			description: {
				default: null,
			},
			fromPrice: {
				default: () => {
					return {
						amount: "00.00",
						currency: "GBP",
					}
				},
			},
		},    
		data() {
			return {
				image: null,
				// showReturnOpts: false,
				// returnKey: false,
			}
		},    
        methods: {
			currencySymbol: (currencyCode) => helpers.currencySymbol(currencyCode),
			viewTour() {
				if(this.id) {
					router.push({ 
						name: 'Tour Details', 
						params: { 
								tour_id: this.id,
							}
						}
					);
				}
			}
		},
		computed: {
			
		},
		created() {
			let imagesPriority = [
				"BANNER_HIRES", // This is actually highest quality, but it's excessive for our purposes. Still better than regular 'BANNER' though.
				"BANNER", // 
				"LARGE_SQUARE", // Highest priority
				"BANNER_DESKTOP",
			];
			// Order images based on priority
			if(this.images && this.images.length > 0) {
				let orderedImages = this.images.sort((a, b) => {
					// Order based on imagesPriority
					let aIndex = imagesPriority.indexOf(a.type);
					let bIndex = imagesPriority.indexOf(b.type);
					if(aIndex > bIndex) {
						return -1;
					}
					if(aIndex < bIndex) {
						return 1;
					}
					return -1;
				});
				// Set the main image href to the highest priority image
				this.image = orderedImages[0].image_href;
			}
		}
    }
</script>

<style scoped>
	.card-outer {
		min-width: 268px;
		width: 100%;
		height: 384px;
		border-radius: 10px;
		position: relative;
		cursor: pointer;
		background: #E5E5E5;
		background-size: cover;
		background-position: center;
	}
	.card-outer .content {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: 19px;
		text-align: center;
		box-sizing: border-box;
		color: white;
		font-weight: 700;

		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.40625) 30.73%, #00272A 100%);
		border-radius: 0px 0px 10px 10px;
	}
	.card-outer .name {
		margin-bottom: 5px;
	}
	.card-outer .length {
		font-size: 12px;
		margin-bottom: 5px;
	}
	.card-outer .length img {
		vertical-align: bottom;
		width: 21px;
	}
	.card-outer .location {
		font-size: 12px;
		margin-bottom: 10px;
	}
	.card-outer .location img {
		vertical-align: bottom;
		width: 8px;
		margin-right: 5px;
	}
	.card-outer .price {
		font-size: 18px;
	}
	.card-outer .price .amount {
		
	}
</style>
